import { Component, OnInit } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit {

  
  constructor(public alertController: AlertController) { }

  ngOnInit() {}

  async SuccessAlert(Title, SubTitle, Message) {

    const alertSuccess = await this.alertController.create({
      header: Title,
      cssClass:'alert-success',
      subHeader: SubTitle,
      message: Message,
      buttons: [
        {
          text: 'OK',
          role: 'Cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }
      ]
    });

    await alertSuccess.present();
  }


  async errorAlert(Title, SubTitle, Message) {

    const alertSuccess = await this.alertController.create({
      header: Title,
      cssClass:'alert-danger',
      subHeader: SubTitle,
      message: Message,
      buttons: [
        {
          text: 'OK',
          role: 'Cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }
      ]
    });

    await alertSuccess.present();
  }





  
}
