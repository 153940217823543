import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class CertificateService {

  constructor(private httpService:HttpService) { }

  getCertificates(body){
    return this.httpService.post("RoadTest/GetMultipleRoadTestCertificateRecords",body);
  }

  addCertificates(body){
    return this.httpService.post("RoadTest/AddRoadTestCertificate",body);
  }

}
